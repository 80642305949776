import React, { FunctionComponent, ReactElement } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { SEO, GeneralJumbotron, SummaryTemplate, AboutUs, CallToAction } from '../components';
import { MainLayout } from '../layouts';
import { PCHImageQuery } from '../shared/interfaces';
import * as sharedStyles from '../styles/shared.module.scss';

const PCHPage: FunctionComponent = (): ReactElement => {
    const queryResult = useStaticQuery<PCHImageQuery>(graphql`
        query {
            pchBackgroundImage: file(name: {eq: "logoCard"}, extension: {eq: "png"}) {
                publicURL
            }
            pchSummaryImage: file(name: {eq: "cardv3"}, extension: {eq: "jpg"}) {
                publicURL
            }
        }
    `);

    return (
        <MainLayout>
            <SEO title="PCH"/>
            <GeneralJumbotron
                title="About Us"
                backgroundImageURL={queryResult?.pchBackgroundImage.publicURL}
                backgroundText="About Us"
            />
            <SummaryTemplate image={queryResult?.pchSummaryImage.publicURL}>
                <h2>{`Revolutionary Approach to Healthcare`}</h2>
                <p>
                    {`
                        Pacific Crest Healthcare IPA is a revolutionary
                        approach to healthcare built on the highest
                        standards and innovation. It is our mission to
                        deliver the highest quality of care to our
                        members.
                    `}
                </p>
                <div className={sharedStyles.listWrapper}>
                    <ul>
                        <li>{`Our gold standard guidelines ensure that our members receive quality, appropriate and personalized treatment`}</li>
                        <li>{`We understand that each member’s condition is unique`}</li>
                        <li>{`With Pacific Crest Healthcare, your treatment plan is supported by well informed providers that work as a team with one goal in mind`}</li>
                    </ul>
                </div>
            </SummaryTemplate>
            <AboutUs/>
            {/* <CallToAction/> */}
        </MainLayout>
    );
}

export default PCHPage;
